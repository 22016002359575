import '../../../css/admin.css';

import { useEffect, useState } from 'react';
import Colors from '../../../utilities/Colors';
import axios from 'axios';
import LogColumn from './LogColumn';

const Logs = () => {
    const [page, setPage] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [cooldown, setCooldown] = useState(0);

    axios.defaults.withCredentials = true;
    
    useEffect(() => {
        document.title = `${process.env.REACT_APP_TITLE} / Logs`;
    }, []);

    useEffect(() => {
        setLoading(hasMore);
        if (Date.now() - cooldown >= process.env.REACT_APP_FETCH_COOLDOWN) {
            setCooldown(Date.now());
            fetch(`/api/logs/${page}`)
                .then((res) => res.json())
                .then((data) => {
                    if (data.length > 0) {
                        setData(prev => [...prev, ...data.filter(log => !prev.some(existing => existing.id === log.id))]);
                    } else {
                        setHasMore(false);
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    console.error('Error fetching logs:', error)
                }); 
        }
    }, [page]);
    
    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight) {
                if (!loading && hasMore && Date.now() - cooldown >= process.env.REACT_APP_FETCH_COOLDOWN) {
                    setPage((prev) => prev + 1);
                }
            }
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [loading, hasMore, cooldown]);

    const renderLogs = () => {
        if (!data || data.length === 0) {
            return (
                <p className="large-text" style={{ padding: 50, color: Colors.outline }}>No logs available - please check back later.</p>
            )
        }

        return data.map((val, index) => (
            <div key={index}>
                <LogColumn index={index} data={val}/>
            </div>
        ));
    }

    return (
        <div className="bordered-body" style={{ '--borderColor': Colors.outline }}>
            <div className="center">
                {renderLogs()}
            </div>
        </div>
    );
};

export default Logs;
