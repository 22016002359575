import { useEffect, useRef, useState } from 'react';

const CursorBackground = () => {
    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

    const cursorRef = useRef();

    useEffect(() => {
        const handleMouseMove = (event) => {
            setCursorPosition({ x: event.clientX, y: event.clientY+window.scrollY });
        };
    
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('scroll', handleMouseMove);
    
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('scroll', handleMouseMove);
        };
    }, []);

    useEffect(() => {
        if (cursorRef.current) {
            cursorRef.current.style.transform = `translate(${cursorPosition.x - 7.5}px, ${cursorPosition.y - 7.5}px)`;
        }
    }, [cursorPosition]);

    return (
        <div className="background-cursor" ref={cursorRef}></div>
    );
};

export default CursorBackground;
