import '../../../../css/sidebar.css';

import { useState } from 'react';
import { useEffect } from 'react';
import Colors from '../../../../utilities/Colors';

const ProfileSidebar = () => {
    const [media, setMedia] = useState([]);

    useEffect(() => {
        fetch(`/api/media`)
            .then((res) => res.json())
            .then((data) => {
                setMedia(data.files.reverse());
            })
            .catch((error) => console.error('Error fetching media:', error));
    }, []);

    return (
        <div className="sidebar-wrapper">
            <div className="media-sidebar" style={{ '--borderColor': Colors.outline }}>
                <p className="medium-text" style={{ color: 'grey' }}>Recent media</p>
                <div className="item-container">
                    {media ? (
                        <>
                            {media.slice(0, 6).map((val, index) => (
                                <li key={index}>
                                    <a target='_blank' href={val}>
                                        <img className="sidebar-image" alt='' src={val}/>
                                    </a>
                                </li>
                            ))}
                        </>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

export default ProfileSidebar;
