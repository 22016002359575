import RoutePaths from '../../../utilities/RoutePaths';
import { useEffect, useState } from 'react';
import Colors from '../../../utilities/Colors';
import { useNavigate } from 'react-router';
import Button from '../../partials/generic/Button';
import Input from '../../partials/generic/Input';
import axios from 'axios';

const CreateLogin = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [subtitle, setSubtitle] = useState('');

    const navigate = useNavigate();

    axios.defaults.withCredentials = true;

    useEffect(() => {
        document.title = `${process.env.REACT_APP_TITLE} / Create Login`;
    }, []);

    const create = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/api/create-login`, {
            username: username,
            password: password
        })
        .then((response) => {
            if (response.data.success) {
                navigate(RoutePaths.DASHBOARD);
            } else {
                setSubtitle(`Invalid credentials. Attempt ${response.data.attempts}/5`);
            }
        })
        .catch((error) => {
            console.error('Create login error:', error);
            setSubtitle("There was a problem creating login");
        });
    };

    return (
        <div className="bordered-body" style={{ '--borderColor': Colors.outline }}>
            <div className="center">
                <p className="title" style={{ color: Colors.text }}>Create login</p>
                <div className="outlined-view">
                    <div className="item-container" style={{ justifyContent: 'center', padding: 20 }}>
                        <Input placeholder={"Username"} value={username} setVariable={setUsername}/>
                        <Input type='password' placeholder={"Password"} value={password} setVariable={setPassword}/>
                    </div>
                    {subtitle ? (
                        <p className="small-text" style={{ color: `rgb(${process.env.REACT_APP_COLOR_RED})` }}>{subtitle}</p>
                    ) : null}
                    <Button text={"Create"} action={create}/>
                </div>
            </div>
        </div>
    );
};

export default CreateLogin;

