import { useState, React } from 'react';
import Colors from '../../../utilities/Colors';
import Icons from '../../../utilities/Icons';
import LogTypes from '../../../utilities/LogTypes';

const LogColumn = ({index, data}) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <div onClick={() => setExpanded(!expanded)} className="value-column">
            <div className="column-component" style={{ border: 'none' }}>
                <p className="medium-text" style={{ color: Colors.outline }}>{index+1}.</p>
            </div>
            <div className="column-component">
                <p className="medium-text" style={{ color: Colors.text }}><span style={{ color: LogTypes[data.type.toUpperCase()].color }}>{LogTypes[data.type.toUpperCase()]?.prefix || "[UNKNOWN]"}</span> {data.title}</p>
                {expanded ? (
                    <div className="expanded-column">
                        <p className="small-text" style={{ color: Colors.text }}><span style={{ color: Colors.outline }}>Type:</span> {data.type.toUpperCase() || "INFO"}</p>
                        <p className="small-text" style={{ color: Colors.text }}><span style={{ color: Colors.outline }}>Weight:</span> {LogTypes[data.type.toUpperCase()]?.weight || "Unknown."}</p>
                        <p className="small-text" style={{ color: Colors.text }}><span style={{ color: Colors.outline }}>Date and time:</span> {data.date.split('T')[0].replaceAll("-", "/")} <span style={{ color: Colors.outline }}>at</span> {data.date.split('T')[1].split(':', 2).join(":")}</p>
                        <p className="small-text" style={{ color: Colors.text }}><span style={{ color: Colors.outline }}>Description:</span> {data.content}</p>
                    </div>
                ) : null}
            </div>
            <div className="column-dropdown" style={{ border: 'none' }}>
                <p className="medium-text" style={{ color: Colors.outline }}><i className={expanded ? Icons.ARROW_UP : Icons.ARROW_DOWN}/></p>
            </div>
        </div>
    );
}

export default LogColumn;
