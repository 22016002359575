import '../../../css/media.css';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Colors from '../../../utilities/Colors';
import PostComponent from '../../partials/generic/profile/PostComponent';
import Icons from '../../../utilities/Icons';
import RoutePaths from '../../../utilities/RoutePaths';

const Post = () => {
    const { postId } = useParams();
    const [data, setData] = useState();

    useEffect(() => {
        document.title = `${process.env.REACT_APP_TITLE} / Post`;

        processData();
    }, [postId]);

    const processData = () => {
        fetch(`/api/post/${postId}`)
        .then((res) => res.json())
        .then((data) => {
            if (data) {
                setData(data);
            }
        })
        .catch((error) => console.error('Error fetching post:', error));
    }


    return (
        <>
            {data ? (
                <div className="bordered-body" style={{ '--borderColor': Colors.outline }}>
                    <div className="post-header">
                        <p className="medium-text" style={{ color: Colors.text }}><a href={RoutePaths.PROFILE} className="back-button"><i className={Icons.ARROW_LEFT}/></a>{process.env.REACT_APP_PROFILE_USERNAME}</p>
                    </div>
                    <PostComponent data={data}/>
                    <p className="medium-text center" style={{ color: Colors.outline, marginTop: 50 }}>This post has no comments!</p>
                </div>
            ) : null}
        </>
    );
};

export default Post;
