const LogTypes = Object.freeze({
    INFO: {
        weight: 100,
        prefix: "[INFO]",
        color: "rgb(61, 160, 173)",
    },
    WARN: {
        weight: 200,
        prefix: "[WARN]",
        color: "rgb(194, 192, 62)"
    },
    ERROR: {
        weight: 300,
        prefix: "[ERROR]",
        color: "rgb(173, 65, 65)"
    },
    CRITICAL: {
        weight: 400,
        prefix: "[CRITICAL]",
        color: "rgb(105, 27, 27)",
    },
    CONNECTION: {
        weight: 500,
        prefix: "[CONNECTION]",
        color: "rgb(139, 35, 143)"
    },
});

export default LogTypes;
