import { useEffect, useState } from 'react';
import Input from '../partials/generic/Input';
import Colors from '../../utilities/Colors';
import axios from 'axios';
import Button from '../partials/generic/Button';
import RoutePaths from '../../utilities/RoutePaths';
import { useNavigate } from 'react-router';

const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [subtitle, setSubtitle] = useState('');

    const navigate = useNavigate();

    axios.defaults.withCredentials = true;

    useEffect(() => {
        document.title = `${process.env.REACT_APP_TITLE} / Contact`;
    }, []);

    const submit = () => {
        if (!name || !email || !message) {
            setSubtitle("Please ensure all missing fields are filled.")
            return;
        }

        axios.post(`${process.env.REACT_APP_API_URL}/api/message`, { name, email, message })
        .then((response) => {
            if (response.data.success) {
                navigate(RoutePaths.HOME);
            }
        })
        .catch((error) => {
            console.error('Contact error:', error);
            setSubtitle("There was a problem processing this request");
        });
    };

    return (
        <div className="bordered-body" style={{ '--borderColor': Colors.outline }}>
            <div className="center">
                <p className="title" style={{ color: Colors.text }}>Contact me</p>
                <div className="outlined-view">
                    <div className="content-center">
                        <Input placeholder={"Name"} value={name} setVariable={setName}/>
                        <Input type='email' placeholder={"Email"} value={email} setVariable={setEmail}/>
                        <Input limit={255} expanded={true} placeholder={"Message"} value={message} setVariable={setMessage}/>
                    </div>
                </div>
                {subtitle ? (
                    <p className="small-text" style={{ color: `rgb(${process.env.REACT_APP_COLOR_RED})` }}>{subtitle}</p>
                ) : null}
                <Button text={"Submit"} action={submit}/>
            </div>
        </div>
    );
};

export default Contact;
