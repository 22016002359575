import { useEffect, useRef } from "react";
import Colors from "../../../../utilities/Colors";

const Video = ({src, partner=null}) => {
    const mainPlayerRef = useRef(null);
    const ambientPlayerRef = useRef(null);

    useEffect(() => {
        const mainVideo = mainPlayerRef.current;
        const ambientVideo = ambientPlayerRef.current;

        if (!mainVideo || !ambientVideo) return;
    
        const syncVideos = () => {
          if (ambientVideo.currentTime !== mainVideo.currentTime) {
            ambientVideo.currentTime = mainVideo.currentTime;
          }
        };
    
        const playAmbientVideo = () => ambientVideo.play();
        const pauseAmbientVideo = () => ambientVideo.pause();
        const seekAmbientVideo = () => ambientVideo.currentTime = mainVideo.currentTime;

        mainVideo.addEventListener('play', playAmbientVideo);
        mainVideo.addEventListener('pause', pauseAmbientVideo);
        mainVideo.addEventListener('seeked', seekAmbientVideo);
    
        mainVideo.addEventListener('timeupdate', syncVideos);
    
        return () => {
          mainVideo.removeEventListener('play', playAmbientVideo);
          mainVideo.removeEventListener('pause', pauseAmbientVideo);
          mainVideo.removeEventListener('seeked', seekAmbientVideo);
          mainVideo.removeEventListener('timeupdate', syncVideos);
        };
    }, []);

    return (
        <div className={partner || partner === 0 ? 'flex-container' : ''}>
            <video className={partner || partner === 0 ? `media media-partner${partner}` : 'expanded-video'} ref={mainPlayerRef} src={src} loop controls={true}/>
            <video className={partner || partner === 0 ? `ambient-media media-partner${partner}` : 'expanded-ambient'} ref={ambientPlayerRef} src={src} muted/>
        </div>
    );
}

export default Video;
