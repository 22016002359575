import '../../../css/project.css';

import { useEffect, useRef, useState } from "react";
import Draggable from 'react-draggable';
import Colors from "../../../utilities/Colors";
import Input from '../../partials/generic/Input';
import Divider from '../../partials/generic/Divider';
import Button from '../../partials/generic/Button';
import { useNavigate } from 'react-router';
import RoutePaths from '../../../utilities/RoutePaths';
import Components from '../../user/projects/Components';
import axios from 'axios';

const CreateProject = () => {
    const [props, setProps] = useState([]);
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState();
    const [description, setDescription] = useState();
    const [propEditor, setPropEditor] = useState(null);
    const [dragPosition, setDragPosition] = useState(null);

    const navigate = useNavigate();
    const dropRef = useRef(null);

    axios.defaults.withCredentials = true;

    useEffect(() => {
        document.title = `${process.env.REACT_APP_TITLE} / Create Project`;
    }, []);

    const handleChange = (event, setValue) => {
        if (event.target.value.length < 100) {
            setValue(event.target.value);
        }
    }

    const handleStop = (e, component) => {
        setDragPosition({x: 0, y: 0});

        const dropZone = dropRef.current;
        const dropZoneRect = dropZone.getBoundingClientRect();
        const draggableRect = e.target.getBoundingClientRect();

        if (
            draggableRect.x + draggableRect.width / 2 >= dropZoneRect.x &&
            draggableRect.x + draggableRect.width / 2 <= dropZoneRect.x + dropZoneRect.width &&
            draggableRect.y + draggableRect.height / 2 >= dropZoneRect.y &&
            draggableRect.y + draggableRect.height / 2 <= dropZoneRect.y + dropZoneRect.height
        ) {
            if (props.length >= process.env.REACT_APP_PROJECT_MAX_COMPONENTS) {
                setSubtitle(`You cannot have more than ${process.env.REACT_APP_PROJECT_MAX_COMPONENTS} components.`)
                return;
            }
            setProps(prev => [...prev, {component, value: 'Edit me'}]);
        }
    };

    const handleValueChange = (event) => {
        setProps((prevProps) => prevProps.map((item, i) => i === propEditor.index ? {...item, value: event} : item ));
    };

    const deleteComponent = () => {
        setProps((prevProps) => prevProps.filter((_, i) => i !== propEditor.index));
        setPropEditor(null);
    }

    const handleError = (event) => {
        event.target.src = process.env.REACT_APP_IMAGE_FALLBACK_URL;
    };

    const save = () => {
        if (!title || !description) {
            setSubtitle("Please ensure all missing fields are filled.");
            return;
        }

        if (props.length < 1) {
            setSubtitle("Please ensure you have at least 5 components");
            return;
        }

        axios.post(`${process.env.REACT_APP_API_URL}/api/add-project`, { title, description, components: props })
            .then((response) => {
                if (response.data.id) {
                    navigate(`${RoutePaths.PROJECT}/${response.data.id}`)
                }
            })
            .catch((error) => {
                console.error('Add project error:', error);
                setSubtitle('There was a problem processing this request.');
            });
    }

    return (
        <>
            <div className="header-wrapper" style={{ '--borderColor': Colors.outline }}>
                <div className="vertical-align flex-container" style={{ gap: 20, width: '100%' }}>
                    <p className="medium-text" style={{ color: Colors.text }}>Title:</p>
                    <input className="large-text text-input" placeholder="Enter title..." value={title} style={{ color: Colors.text }} onChange={(e) => handleChange(e, setTitle)}/>
                    <p className="medium-text" style={{ color: Colors.text }}>Description:</p>
                    <input className="large-text text-input" placeholder="Enter description..." value={description} style={{ color: Colors.text }} onChange={(e) => handleChange(e, setDescription)}/>
                    {subtitle ? (
                        <p className="center medium-text" style={{ color: `rgb(${process.env.REACT_APP_COLOR_RED})` }}>{subtitle}</p>
                    ) : null}
                </div>
                <Button text={"Save"} action={save}/>
                <Button text={"Cancel"} action={() => navigate(RoutePaths.DASHBOARD)}/>
            </div>

            <div className="draggable-wrapper" style={{ '--borderColor': Colors.outline }}>
                <p className="large-text" style={{ color: Colors.text }}>Component table</p>
                <div className="item-container">
                    {Object.values(Components).map((com, index) => (
                        <div key={index}>
                            <Draggable onStop={(e) => handleStop(e, com)} position={dragPosition}>
                                <div className="draggable-component" style={{ '--widgetColor': Colors.base }}>
                                    <i className={`large-text ${com.icon}`} style={{ color: Colors.text }}/>
                                    <p className="medium-text" style={{ color: Colors.text }}>{com.title}</p>
                                </div>
                            </Draggable>
                        </div>
                    ))};
                </div>
            </div>

            {propEditor ? (
                <div className="editor-wrapper" style={{ '--borderColor': Colors.outline }}>
                    <p className="large-text" style={{ color: Colors.text }}>Edit component</p>
                    <div className="vertical-align flex-container">
                        {propEditor.prop.component.editable ? (
                            <>
                                {propEditor.component === Components.IMAGE ? (
                                    <>
                                        <p className="medium-text" style={{ color: Colors.text }}>Image URL:</p>
                                        <Input placeholder={"URL"} value={propEditor.prop.value} setVariable={(e) => handleValueChange(e)}/>
                                    </>
                                ) : (
                                    <>
                                        <p className="medium-text" style={{ color: Colors.text }}>Value:</p>
                                        <Input limit={500} placeholder={"Value"} value={propEditor.prop.value} setVariable={(e) => handleValueChange(e)}/>
                                    </>
                                )}
                            </>
                        ) : null}
                    </div>
                    <Button text={"Delete"} action={deleteComponent}/>
                </div>
            ) : null}

            <div className="bordered-body" style={{ '--borderColor': Colors.outline }}>
                {props.length <= 0 ? (
                    <>
                        <p className="large-text center" style={{ color: Colors.outline, padding: 50 }}>Your components will appear here!</p>
                        <Divider color={Colors.outline}/>
                    </>
                ) : (
                    <>
                        {props.map((prop, index) => (
                            <div key={index}>
                                {prop.value ? (
                                    <div onClick={() => setPropEditor({index, prop})} className="project-component" style={{'--hoverColor': Colors.base }}>
                                        {prop.component === Components.HEADER ? (
                                            <p className="center title" style={{ color: Colors.text }}>{prop.value}</p>
                                        ) : (prop.component === Components.TEXT) ? (
                                            <p className="center large-text" style={{ color: 'grey' }}>{prop.value}</p>
                                        ) : (prop.component === Components.IMAGE) ? (
                                            <img alt='' className="component-image" src={prop.value} onError={handleError}/>
                                        ) : (
                                            <Divider color={Colors.outline}/>
                                        )}
                                    </div>
                                ) : null}
                            </div>
                        ))}
                    </>
                )}

                <div ref={dropRef} className="drop-component">
                    <p className="large-text" style={{ color: 'grey' }}>Drop your component here</p>
                </div>
            </div>


            <div className="center information-container">
                <p className="small-text" style={{ color: 'grey' }}>Components: <span style={{ color: Colors.text }}>{props.length}</span></p>
            </div>
        </>
    );
};

export default CreateProject;
