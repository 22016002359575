import '../../../css/media.css';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Divider from '../../partials/generic/Divider';
import Colors from '../../../utilities/Colors';

const Project = () => {
    const { projectId } = useParams();
    const [components, setComponents] = useState();

    useEffect(() => {
        document.title = `${process.env.REACT_APP_TITLE} / Project`;

        processComponents();
    }, [projectId]);

    const processComponents = () => {
        fetch(`/api/components/${projectId}`)
        .then((res) => res.json())
        .then((data) => {
            if (data) {
                setComponents(data);
                
            }
        })
        .catch((error) => console.error('Error fetching project:', error));
    }

    const handleError = (event) => {
        event.target.src = process.env.REACT_APP_IMAGE_FALLBACK_URL;
    };

    return (
        <div className="bordered-body" style={{ '--borderColor': Colors.outline }}>
            {components ? (
                <>
                    {components.map((component, index) => (
                        <div key={index}>
                            {parseInt(component.type) === 0 ? (
                                <p className="center title" style={{ color: Colors.text }}>{component.value}</p>
                            ) : (parseInt(component.type) === 1) ? (
                                <p className="center large-text" style={{ color: 'grey' }}>{component.value}</p>
                            ) : (parseInt(component.type) === 2) ? (
                                <img alt='' className="component-image" src={component.value} onError={handleError}/>
                            ) : (
                                <Divider color={Colors.outline}/>
                            )}
                        </div>
                    ))}
                </>                
            ) : null}
        </div>
    );
};

export default Project;
