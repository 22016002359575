export const formatLongDate = (input) => {
    const date = (input instanceof Date) ? input : new Date(input);
    return date.toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" });
}

export const formatDate = (input) => {
    const date = (input instanceof Date) ? input : new Date(input);
    const formatter = new Intl.RelativeTimeFormat('en');
    const ranges = {
        years: 3600 * 24 * 365,
        months: 3600 * 24 * 30,
        weeks: 3600 * 24 * 7,
        days: 3600 * 24,
        hours: 3600,
        minutes: 60,
        seconds: 1
    };
    const secondsElapsed = (date.getTime() - Date.now()) / 1000;
    for (let key in ranges) {
        if (ranges[key] < Math.abs(secondsElapsed)) {
        const delta = secondsElapsed / ranges[key];
        return formatter.format(Math.round(delta), key);
        }
    }
}

export const formatInt = (value) => {
    return Intl.NumberFormat('en', { notation: 'compact' }).format(value);
}

export const sanitizeUpload = (file) => {
    const mb = (file.size / (1024 * 1024)).toFixed(2);
    if (mb < process.env.REACT_APP_MAX_UPLOAD) {
        return true;
    }
    return false;
}
