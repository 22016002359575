import Icons from "../../../utilities/Icons";

export const Components = Object.freeze({
    HEADER: {id: 0, editable: true, title: "Header", icon: Icons.GENERIC_HEADER},
    TEXT: {id: 1, editable: true, title: "Text", icon: Icons.GENERIC_TEXT},
    IMAGE: {id: 2, editable: true, title: "Image", icon: Icons.GENERIC_IMAGE},
    DIVIDER: {id: 3, editable: false, title: "Divider", icon: Icons.GENERIC_LINE}
});

export default Components;
