const Icons = Object.freeze({
    HOME_STAR: 'fa-solid fa-star',
    HOME_CODE_FORK: 'fa-solid fa-code-fork',
    HOME_GITHUB: 'fa-brands fa-github',
    HOME_ARROW_LEFT: 'fa-solid fa-arrow-left-long',
    HOME_CIRCLE_INFORMATION: 'fa-solid fa-circle-info',

    NAV_HOME: 'fa-solid fa-house',
    
    GENERIC_HEADER: 'fas fa-heading',
    GENERIC_IMAGE: 'fa-solid fa-image',
    GENERIC_COMPOSE: 'fa-solid fa-paper-plane',
    GENERIC_TEXT: 'fa-solid fa-bars',
    GENERIC_LINE: 'fa-solid fa-minus',
    
    POST_MENU: 'fa-solid fa-ellipsis',
    POST_COMMENT: 'fa fa-comment',
    POST_REPOST: 'fa fa-retweet',
    POST_LIKE: 'fa fa-heart',
    POST_INSIGHTS: 'fa-solid fa-chart-column',
    POST_VERIFIED: 'fa-solid fa-certificate',
    
    ARROW_UP: 'fa-solid fa-angle-up',
    ARROW_DOWN: 'fa-solid fa-angle-down',
    ARROW_LEFT: 'fa-solid fa-arrow-left-long',
    ARROW_RIGHT: 'fa-solid fa-arrow-right-long',
    ACTION_ADD: 'fa-solid fa-plus',

    ACTION_CLOSE: 'fa-solid fa-x',
});

export default Icons;
