import '../../../css/navbar.css';

import Colors from '../../../utilities/Colors';
import RoutePaths from '../../../utilities/RoutePaths';

const Navbar = () => {
    return (
        <div className="navbar-wrapper" style={{ '--borderColor': Colors.outline }}>
            <a href={RoutePaths.HOME}><p className="medium-text navbar-text">~/home</p></a>
            <a href={RoutePaths.PROFILE}><p className="medium-text navbar-text">~/profile</p></a>
            <a href={RoutePaths.CONTACT}><p className="medium-text navbar-text">~/contact</p></a>
            <a href={RoutePaths.DASHBOARD}><p className="medium-text navbar-text">~/dashboard</p></a>
        </div>
    );
}

export default Navbar;
