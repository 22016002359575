import { useEffect } from 'react';
import Colors from '../../../utilities/Colors';
import axios from 'axios';
import { useState } from 'react';

const ManageProjects = () => {
    const [data, setData] = useState([]);

    axios.defaults.withCredentials = true;
    
    useEffect(() => {
        document.title = `${process.env.REACT_APP_TITLE} / Manage Projects`;
    }, []);

    useEffect(() => {
        fetch(`/api/projects`)
            .then((res) => res.json())
            .then((data) => {
                setData(data);
            })
            .catch((error) => {
                console.error('Error fetching projects:', error)
            }); 
    }, []);

    const renderProjects = () => {
        if (!data || data.length === 0) {
            return (
                <p className="large-text" style={{ padding: 50, color: Colors.outline }}>No logs available - please check back later.</p>
            )
        }

        return data.map((val, index) => (
            <div key={index} className="value-column">
                <div className="column-component" style={{ border: 'none' }}>
                    <p className="medium-text" style={{ color: Colors.outline }}>{index+1}.</p>
                </div>
                <div className="column-component">
                    <p className="medium-text" style={{ color: Colors.text }}>{val.title} - {val.description}</p>
                </div>
            </div>
        ));
    }

    return (
        <div className="bordered-body" style={{ '--borderColor': Colors.outline }}>
            <div className="center">
                {renderProjects()}
            </div>
        </div>
    );
};

export default ManageProjects;
