import { useNavigate } from 'react-router-dom';
import RoutePaths from '../../../../utilities/RoutePaths';
import Colors from '../../../../utilities/Colors';

const FilterPostContent = ({ content, links=true }) => {
    let hashtags = [];
    let mentions = [];

    const navigate = useNavigate();

    const handleLinkClick = (e, link) => {
        if (links) {
            e.preventDefault();
            navigate('/link', { state: { link } });
        }
    };

    const formatted = content.split(' ').map((word, index) => {
        if (word.startsWith('https://')) {
            return (
                <span className="medium-text" key={index} onClick={(e) => handleLinkClick(e, word)} style={{ color: `rgb(${process.env.REACT_APP_COLOR_LINK})`, cursor: 'pointer' }}>{word}</span>
            );
        }
        if (word.startsWith('#')) {
            hashtags.push(word);
            return (
                <a key={index} onClick={(e) => {
                    if (links) {
                        e.stopPropagation();
                        navigate(`${RoutePaths.HOME}/${word.replaceAll('#', '')}`);
                    }
                }} className="medium-text link" style={{ color: `rgb(${process.env.REACT_APP_COLOR_LINK})`, cursor: 'pointer' }}>{word}</a>
            );
        }
        if (word.startsWith('@')) {
            mentions.push(word);
            return (
                <span className="medium-text" key={index} onClick={() => links ? navigate(`${RoutePaths.USER}/${word.replace('@', '')}`) : {}} style={{ color: `rgb(${process.env.REACT_APP_COLOR_LINK})`, cursor: 'pointer' }}>{word}</span>
            );
        }
        
        return (<span className="medium-text" key={index} style={{ color: Colors.text }}>{word}</span>);
    });

    return <div style={{ marginTop: 20 }}>{formatted.reduce((prev, curr) => [prev, ' ', curr])}</div>;
};

export default FilterPostContent;
