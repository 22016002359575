import { useEffect } from 'react';
import Colors from '../../utilities/Colors';
import Divider from '../partials/generic/Divider'
import RoutePaths from '../../utilities/RoutePaths';
import axios from 'axios';

const Dashboard = () => {
    axios.defaults.withCredentials = true;

    useEffect(() => {
        document.title = `${process.env.REACT_APP_TITLE} / Dashboard`;
    }, []);

    return (
        <div className="bordered-body" style={{ '--borderColor': Colors.outline }}>
            <p className="title center" style={{ color: Colors.text }}>Dashboard</p>
            <fieldset style={{ borderColor: Colors.outline }}>
                <legend style={{ color: Colors.text }}>General</legend>
                <a href={RoutePaths.LOGS}><p className="link medium-text dashboard-button" style={{ color: Colors.text }}>Logs</p></a>
                <Divider color={Colors.outline}/>
                <a href={RoutePaths.INBOX}><p className="link medium-text dashboard-button" style={{ color: Colors.text }}>Inbox</p></a>
            </fieldset>

            <fieldset style={{ borderColor: Colors.outline }}>
                <legend style={{ color: Colors.text }}>Login</legend>
                <a href={RoutePaths.CREATE_LOGIN}><p className="link medium-text dashboard-button" style={{ color: Colors.text }}>Create login</p></a>
            </fieldset>

            <fieldset style={{ borderColor: Colors.outline }}>
                <legend style={{ color: Colors.text }}>Projects</legend>
                <a href={RoutePaths.CREATE_PROJECT}><p className="link medium-text dashboard-button" style={{ color: Colors.text }}>Create project</p></a>
                <Divider color={Colors.outline}/>
                <a href={RoutePaths.MANAGE_PROJECTS}><p className="link medium-text dashboard-button" style={{ color: Colors.text }}>Manage projects</p></a>
            </fieldset>
        </div>
    );
};

export default Dashboard;
