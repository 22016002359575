import './css/style.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ProtectedRoute from './utilities/ProtectedRoute.js'
import RoutePaths from './utilities/RoutePaths.js';
import { useEffect, useState } from 'react';
import axios from 'axios';

import Loading from './components/generic/loading.js';

import CursorBackground from './components/partials/generic/CursorBackground.js';
import Navbar from './components/partials/navbar/navbar.js';
import ActionWidget from './components/partials/navbar/action-widget.js';

import Login from './components/user/login.js';

import Home from './components/user/home.js';
import Project from './components/user/projects/project.js';
import Profile from './components/user/profile/profile.js';
import ProfileSidebar from './components/partials/generic/sidebar/profile-sidebar.js';
import Post from './components/user/profile/post.js';
import Media from './components/user/profile/media.js';
import Contact from './components/user/contact.js';

import Dashboard from './components/admin/dashboard.js';
import Inbox from './components/admin/messages/inbox.js';
import Logs from './components/admin/logs/logs.js';
import CreateProject from './components/admin/projects/create-project.js';
import ManageProjects from './components/admin/projects/manage-projects.js';
import CreateLogin from './components/admin/logins/create-login.js';

import Error from './components/generic/error.js';

const App = () => {
    const [isAuth, setIsAuth] = useState(false);
    const [loading, setLoading] = useState(true);

    axios.defaults.withCredentials = true;

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/session`)
            .then((response) => {
                setIsAuth(response.data.logged);
            })
            .catch((error) => {
                setIsAuth(false);
                console.error('Error fetching session data:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return loading ? <Loading/> : (
        <Router>
            <Routes>
                <Route element={<ProtectedRoute isAuth={isAuth ? false : true} fallback={RoutePaths.DASHBOARD}/>}>
                    <Route path={RoutePaths.LOGIN} element={<><Navbar/><ActionWidget/><Login setIsAuth={setIsAuth}/></>}/>
                </Route>

                <Route path={RoutePaths.HOME} element={<><CursorBackground/><Navbar/><ActionWidget/><Home/></>}/>
                <Route path={`${RoutePaths.PROJECT}/:projectId`} element={<><Navbar/><ActionWidget/><Project/></>}/>
                <Route path={RoutePaths.PROFILE} element={<><Navbar/><ActionWidget/><ProfileSidebar/><Profile/></>}/>
                <Route path={`${RoutePaths.POST}/:postId`} element={<Post/>}/>
                <Route path={`${RoutePaths.POST}/:postId/:m`} element={<Media/>}/>
                <Route path={RoutePaths.CONTACT} element={<><Navbar/><ActionWidget/><Contact/></>}/>

                <Route element={<ProtectedRoute isAuth={isAuth ? true : false} fallback={RoutePaths.LOGIN}/>}>
                    <Route path={RoutePaths.DASHBOARD} element={<><Navbar/><ActionWidget/><Dashboard/></>}/>
                    <Route path={RoutePaths.LOGS} element={<><Navbar/><ActionWidget/><Logs/></>}/>
                    <Route path={RoutePaths.INBOX} element={<><Navbar/><ActionWidget/><Inbox/></>}/>
                    <Route path={RoutePaths.CREATE_PROJECT} element={<CreateProject/>}/>
                    <Route path={RoutePaths.MANAGE_PROJECTS} element={<><Navbar/><ActionWidget/><ManageProjects/></>}/>
                    <Route path={RoutePaths.CREATE_LOGIN} element={<><Navbar/><ActionWidget/><CreateLogin/></>}/>
                </Route>

                <Route path={RoutePaths.ERROR} element={<Error/>}/>
            </Routes>
        </Router>
    );
};

export default App;
