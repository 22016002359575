import React, { useEffect } from 'react';
import Colors from '../../utilities/Colors.js';

const Loading = () => {
    useEffect(() => {
        document.title = `${process.env.REACT_APP_TITLE} / Loading...`;
    }, []);

    return (
        <div className="center vertical-center">
            <div className="loader" style={{ '--primaryColor': Colors.widgets.aqua.button, '--secondaryColor': Colors.outline }}/>
        </div>
    );
}

export default Loading;
