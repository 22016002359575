import '../../../../css/post.css';

import Divider from '../Divider';
import Image from '../media/Image';
import Video from '../media/Video';
import { useState, useEffect } from 'react';
import Icons from '../../../../utilities/Icons';
import Colors from '../../../../utilities/Colors';
import FilterPostContent from './FIlterPostContent';
import { formatInt } from '../../../../utilities/Utils';
import RoutePaths from '../../../../utilities/RoutePaths';
import VerificationBadge from '../VerificationBadge';
import Tooltip from '../Tooltip';
import { useNavigate } from 'react-router';

const PostComponent = ({data}) => {
    const [media, setMedia] = useState();
    const [collapsed, setCollapsed] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/api/media/${data.postId}`)
        .then((res) => res.json())
        .then((data) => {
            if (data) {
                setMedia(data);
            }
        })
        .catch((error) => console.error('Error fetching media:', error));
    }, [data]);

    const toggleCollapse = () => {
        setCollapsed(!collapsed);
    }

    const renderMedia = () => {
        if (media && media.files && Array.isArray(media.files)) {
            const fileLen = media.files.length;
            return media.files.map((file, index) => {
                const name = file.toLowerCase();
                if (name.endsWith('.mp4')) {
                    return (
                        <a key={index} href={`${RoutePaths.POST}/${data.postId}/${index}`}>
                            <Video key={index} src={file} partner={fileLen > 2 ? index === 0 ? 32 : index === 1 ? 43 : index === 2 ? fileLen > 3 ? 12 : 3 : 41 : fileLen === 2 ? index === 0 ? 4 : 2 : 0} controls width="100%"/>
                        </a>
                    )
                } else if (name.endsWith('.jpg') || name.endsWith('.png') || name.endsWith('.jpeg') || name.endsWith('.gif')) {
                    return (
                        <a key={index} href={`${RoutePaths.POST}/${data.postId}/${index}`}>
                            <Image alt='' key={index} src={file} partner={fileLen > 2 ? index === 0 ? 32 : index === 1 ? 43 : index === 2 ? fileLen > 3 ? 12 : 3 : 41 : fileLen === 2 ? index === 0 ? 4 : 2 : 0}/>
                        </a>
                    )
                } else {
                    console.warn(`Unsupported file type: ${file}`);
                }
            });
        }
    };    

    return (
        <div onClick={() => navigate(`${RoutePaths.POST}/${data.postId}`)} className="post" style={{ '--hoverColor': Colors.base }}>
            <div className="post-container">
                <div className="flex-container">
                    <img alt='' src={process.env.REACT_APP_PROFILE_PICTURE} className="post-profile"/>
                    <div style={{ margin: 10 }}>
                        <p className="medium-text post-menu" style={{ color: 'grey' }}><i className={Icons.POST_MENU}/></p>
                        <p className="medium-text" style={{ color: Colors.text }}>{process.env.REACT_APP_PROFILE_DISPLAY_NAME}
                            <VerificationBadge>
                                <i className={Icons.POST_VERIFIED} style={{ width: '15px', marginLeft: '10px', color: Colors.widgets.aqua.button }}/>
                            </VerificationBadge>
                        </p>
                        <p className="small-text" style={{ color: 'grey' }}>@{process.env.REACT_APP_PROFILE_USERNAME}</p>
                        <FilterPostContent content={collapsed && data.content.length > process.env.REACT_APP_CHAR_BREAK_LIMIT ? `${data.content.slice(0, process.env.REACT_APP_CHAR_BREAK_LIMIT)}... ` : `${data.content} `}/>
                        {data.content.length > process.env.REACT_APP_CHAR_LIMIT ? (
                            <>
                                {collapsed ? (
                                    <span className="link" onClick={(e) => toggleCollapse(e)} style={{ color: `rgb(${process.env.REACT_APP_COLOR_LINK})` }}>Read more</span>
                                ) : (
                                    <span className="link" onClick={(e) => toggleCollapse(e)} style={{ color: `rgb(${process.env.REACT_APP_COLOR_LINK})` }}>Read less</span>
                                )}
                            </>
                        ) : null}
                    </div>
                </div>
            </div>

            <div className="media-container">
                {renderMedia()}
            </div>

            <div className="flex-container">
                <div className="icon-wrapper">
                    <Tooltip message={"Comment"}>
                        <button className="widget" style={{ '--contentColor': 'grey', '--hoverColor': process.env.REACT_APP_COLOR_BLUE }}><i className={`icon ${Icons.POST_COMMENT}`} aria-hidden="true"></i> {formatInt(1000)}</button>
                    </Tooltip>

                    <Tooltip message={"Repost"}>
                        <button className="widget" style={{ '--contentColor': 'grey', '--hoverColor': process.env.REACT_APP_COLOR_GREEN }}><i className={`icon ${Icons.POST_REPOST}`} aria-hidden="true"></i> {formatInt(1000)}</button>
                    </Tooltip>

                    <Tooltip message={"Like"}>
                        <button className="widget" style={{ '--contentColor': 'grey', '--hoverColor': process.env.REACT_APP_COLOR_RED }}><i className={`icon ${Icons.POST_LIKE}`} aria-hidden="true"></i> {formatInt(1000)}</button>
                    </Tooltip>

                    <Tooltip message={"Insights"}>
                        <button className="widget" style={{ '--contentColor': 'grey', '--hoverColor': process.env.REACT_APP_COLOR_PINK }}><i className={`icon ${Icons.POST_INSIGHTS}`} aria-hidden="true"></i> {formatInt(1000)}</button>
                    </Tooltip>
                </div>
                <Tooltip message={"Share"}>
                    <button className="widget" style={{ '--contentColor': 'grey', '--hoverColor': process.env.REACT_APP_COLOR_GREEN }}><i className={`icon ${Icons.POST_SHARE}`} aria-hidden="true"></i></button>
                </Tooltip>
            </div>
            <Divider color={Colors.outline}/>
        </div>
    );
}

export default PostComponent;
