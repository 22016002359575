import { useState, React } from 'react';
import Colors from '../../../utilities/Colors';
import Icons from '../../../utilities/Icons';

const MessageColumn = ({index, data}) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <div key={index} onClick={() => setExpanded(!expanded)} className="value-column">
            <div className="column-component" style={{ border: 'none' }}>
                <p className="medium-text" style={{ color: Colors.outline }}>{index+1}.</p>
            </div>
            <div className="column-component">
                <p className="medium-text" style={{ color: Colors.text }}><span style={{ color: Colors.text }}>{data.name} - {data.message.length > 20 ? `${data.message.substring(0, 60)}...` : data.message}</span> {data.title}</p>
                {expanded ? (
                    <div className="expanded-column">
                        <p className="small-text" style={{ color: Colors.text }}><span style={{ color: Colors.outline }}>Name:</span> {data.name}</p>
                        <p className="small-text" style={{ color: Colors.text }}><span style={{ color: Colors.outline }}>Email:</span> {data.email}</p>
                        <p className="small-text" style={{ color: Colors.text }}><span style={{ color: Colors.outline }}>Date and time:</span> {data.date.split('T')[0].replaceAll("-", "/")} <span style={{ color: Colors.outline }}>at</span> {data.date.split('T')[1].split(':', 2).join(":")}</p>
                        <p className="small-text" style={{ color: Colors.text }}><span style={{ color: Colors.outline }}>Message:</span> {data.message}</p>
                    </div>
                ) : null}
            </div>
            <div className="column-dropdown" style={{ border: 'none' }}>
                <p className="medium-text" style={{ color: Colors.outline }}><i className={expanded ? Icons.ARROW_UP : Icons.ARROW_DOWN}/></p>
            </div>
        </div>
    );
}

export default MessageColumn;
