import '../../../css/media.css';

import { useEffect, useState } from 'react';
import Icons from '../../../utilities/Icons';
import { useNavigate, useParams } from 'react-router';
import Colors from '../../../utilities/Colors';
import RoutePaths from '../../../utilities/RoutePaths';
import Video from '../../partials/generic/media/Video';
import Tooltip from '../../partials/generic/Tooltip';

const Media = () => {
    const { postId, m } = useParams();
    const [data, setData] = useState();
    const [media, setMedia] = useState();
    const [currentMedia, setCurrentMedia] = useState(m);
    const max_scroll_items = process.env.REACT_APP_MAX_SCROLL_ITEMS;
    const [scrollPage, setScrollPage] = useState(Math.floor(m/parseInt(max_scroll_items)));

    const navigate = useNavigate();

    useEffect(() => {
        document.title = `${process.env.REACT_APP_TITLE} / Media`;
    }, [data, scrollPage, currentMedia]);

    useEffect(() => {
        setScrollPage(Math.floor(currentMedia/parseInt(max_scroll_items)));
    }, [currentMedia]);

    useEffect(() => {
        processData();
        processMedia();
    }, [postId]);

    const processData = () => {
        fetch(`/api/post/${postId}`)
        .then((res) => res.json())
        .then((data) => {
            if (data) {
                setData(data);
            }
        })
        .catch((error) => console.error('Error fetching post:', error));
    }

    const processMedia = () => {
        fetch(`/api/media/${postId}`)
        .then((res) => res.json())
        .then((data) => {
            if (data) {
                setMedia(data);
            }
        })
        .catch((error) => console.error('Error fetching media:', error));
    }

    const renderCurrentMedia = () => {
        const start = parseInt(max_scroll_items) * scrollPage;
        const end = start + parseInt(max_scroll_items);
    
        return media.files.slice(start, end).map((media, index) => (
            <img alt='' src={media} onClick={() => setCurrentMedia(index+start)} className={`media-preview ${index+start === parseInt(currentMedia) ? 'selected-media' : ''}`}/>
        ));
    };

    const prevMedia = () => {
        setCurrentMedia(parseInt(currentMedia)-1 < 0 ? media.files.length-1 : parseInt(currentMedia)-1);
    }

    const nextMedia = () => {
        setCurrentMedia(parseInt(currentMedia)+1 > media.files.length-1 ? 0 : parseInt(currentMedia)+1)
    }

    return (
        <>
            {data && media ? (
                <>
                    {!media.files[parseInt(currentMedia)].endsWith(".mp4") ? (
                        <img className="background blur" style={{ opacity: .5 }} alt='' src={media.files[parseInt(currentMedia) < 0 || parseInt(currentMedia) > media.files.length ? 0 : parseInt(currentMedia)]}/>
                    ) : null}
                    <div className="scroll-body"/>
                    <div onClick={() => navigate(RoutePaths.PROFILE)} className="close-body">
                        <Tooltip message={"Close"}>
                            <p className="close-widget" style={{ color: Colors.outline }}><i className={Icons.ACTION_CLOSE}/></p>
                        </Tooltip>
                    </div>
                    <div className="center">
                        <>
                            <div className="expanded-wrapper">
                                <div onClick={prevMedia} className="sidebar-body">
                                    <Tooltip message={"Last"}>
                                        <p className="sidebar-widget"><i className={Icons.ARROW_LEFT}/></p>
                                    </Tooltip>
                                </div>
                                {media.files[parseInt(currentMedia)].endsWith(".mp4") ? (
                                    <Video src={media.files[parseInt(currentMedia) < 0 || parseInt(currentMedia) > media.files.length ? 0 : parseInt(currentMedia)]} controls width="100%"/>
                                ) : (
                                    <img className="expanded-media" alt='' src={media.files[parseInt(currentMedia) < 0 || parseInt(currentMedia) > media.files.length ? 0 : parseInt(currentMedia)]}/>
                                )}
                                <div onClick={nextMedia} className="sidebar-body">
                                    <Tooltip message={"Next"}>
                                        <p className="sidebar-widget"><i className={Icons.ARROW_RIGHT}/></p>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="flex-container">
                                <div onClick={prevMedia} className="scroll-sidebar">
                                    <p className="sidebar-widget"><i className={Icons.ARROW_LEFT}/></p>
                                </div>
                                <div className="media-scroll">
                                    <div className="image-container">
                                        {renderCurrentMedia()}
                                    </div>
                                </div>
                                <div onClick={nextMedia} className="scroll-sidebar">
                                    <p className="sidebar-widget"><i className={Icons.ARROW_RIGHT}/></p>
                                </div>
                            </div>
                        </>
                    </div>
                </>
            ) : null}
        </>
    );
};

export default Media;
